import {
  CalendarMonthOutlined as CalendarIcon,
  ChatBubbleOutline as CommentIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  EventOutlined as CoursesIcon,
  FaceOutlined as UserIcon,
  FilterList as FilterIcon,
  Google as GoogleIcon,
  GppBadOutlined as ExpiredIcon,
  GppGoodOutlined as VerifiedIcon,
  Home as SelectedCustomerIcon,
  InfoOutlined as InfoIcon,
  InputOutlined as MoveIcon,
  LocalOfferOutlined as CourseTypeIcon,
  Menu as MenuIcon,
  NearMe as NavigateIcon,
  OpenInNew as ExternalLinkIcon,
  People as ParticipantsIcon,
  PersonSearch as ParticipantSearchIcon,
  PowerSettingsNew as LogoutIcon,
  RecentActorsOutlined as CustomersIcon,
  ReportProblemOutlined as AlertIcon,
  Restore as UndoIcon,
  Search as SearchIcon,
  SettingsOutlined as SettingsIcon,
  SpaceDashboardOutlined as DashboardIcon,
  TaskOutlined as CertificateTypeIcon,
} from '@mui/icons-material';

export const sxSubtleIcon = {
  color: '#4B4C4E',
};

export {
  AlertIcon,
  CalendarIcon,
  CertificateTypeIcon,
  CheckIcon,
  CloseIcon,
  CommentIcon,
  CopyIcon,
  CoursesIcon,
  CourseTypeIcon,
  CustomersIcon,
  DashboardIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ExpiredIcon,
  ExternalLinkIcon,
  FilterIcon,
  GoogleIcon,
  InfoIcon,
  LogoutIcon,
  MenuIcon,
  MoveIcon,
  NavigateIcon,
  ParticipantSearchIcon,
  ParticipantsIcon,
  SearchIcon,
  SelectedCustomerIcon,
  SettingsIcon,
  UndoIcon,
  UserIcon,
  VerifiedIcon,
};
