import { User as FirebaseUser } from 'firebase/auth';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import MainLayout from './components/layout/MainLayout';
import LoadingIndicator from './components/misc/LoadingIndicator';
import { auth } from './firebase/firebase';
import { useAppDispatch } from './store';
import { useGetV1UsersByIdQuery } from './store/api/backendApi';
import {
  setAuthenticated, setData,
  setRole, setRoles,
} from './store/userSlice';
import CoursesView from './views/courses/CoursesView';
import CourseView from './views/courses/CourseView';
import CustomersView from './views/customers/CustomersView';
import CustomerView from './views/customers/CustomerView';
import NewCustomerView from './views/customers/NewCustomerView';
import DashboardView from './views/DashboardView';
import LoginView from './views/LoginView';
import LogoutView from './views/LogoutView';
import NotFoundView from './views/NotFoundView';
import ParticipantsView from './views/participants/ParticipantsView';
import CertVerficationView from './views/registration/CertVerificationView';
import ParticipantEntrySuccessView from './views/registration/ParticipantEntrySuccessView';
import ParticipantEntryView from './views/registration/ParticipantEntryView';
import SettingsView from './views/settings/SettingsView';

type RequireAuthProps = {
  authUser: FirebaseUser | null | undefined,
  children: JSX.Element
}

function RequireAuth({ authUser, children }: RequireAuthProps) {
  const location = useLocation();

  if (!authUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const [authUser, isLoadingAuth] = useAuthState(auth);
  const { data: userData, isLoading: isLoadingUser } = useGetV1UsersByIdQuery({ id: authUser?.uid ?? '' }, { skip: !authUser });

  useEffect(() => {
    if (authUser && userData) {
      dispatch(setAuthenticated(true));
      dispatch(setRole(userData.data.role));
      dispatch(setData(userData.data));
    } else {
      dispatch(setAuthenticated(false));
      dispatch(setRoles([]));
      dispatch(setData(null));
    }
  }, [authUser, userData]);

  if (isLoadingAuth || isLoadingUser) {
    return <LoadingIndicator caption="Ladataan käyttäjätietoja" />;
  }

  return (
    <Routes>
      <Route path="/login" Component={LoginView} />
      <Route path="/logout" Component={LogoutView} />
      <Route element={(
        <RequireAuth authUser={authUser}>
          <MainLayout />
        </RequireAuth>
        )}
      >
        <Route path="/courses" Component={CoursesView} />
        <Route path="/courses/:id" Component={CourseView} />
        <Route path="/customers" Component={CustomersView} />
        <Route path="/customers/new" Component={NewCustomerView} />
        <Route path="/customers/:id" Component={CustomerView} />
        <Route path="/participants" Component={ParticipantsView} />
        <Route path="/dashboard" Component={DashboardView} />
        <Route path="/settings" Component={SettingsView} />
      </Route>

      <Route path="/" Component={ParticipantEntryView} />
      <Route path="/success" Component={ParticipantEntrySuccessView} />
      <Route path="/verify" Component={CertVerficationView} />
      <Route path="*" Component={NotFoundView} />
    </Routes>
  );
}

export default App;
